import { createConstants } from '../../helper/action';
import { env, STAGE } from '../../config/global';
export const GET_USER_PERMISSION = 'app/GET_USER_PERMISSION';
export const [
  GET_USER_PERMISSION_REQUEST,
  GET_USER_PERMISSION_SUCCESS,
  GET_USER_PERMISSION_ERROR,
] = createConstants(GET_USER_PERMISSION);
export const CLEAR_PERMISSION = 'CLEAR_PERMISSION';

export const DEFAULT_TITLE = 'Kyousaikai';

export const mapScreenTitle = {
  '': '共済会システムメニュー',
  BEN002: '結婚祝金登録',
  BEN003: '出産祝金登録',
  BEN004: '家族弔慰金登録',
  BEN005: '本人弔慰金登録',
  BEN006: '後遺障害補償金登録',
  BEN007: '傷病/入院/通院/差額ベッド登録',
  BEN008: '配偶者健診補助金',
  BEN009: '災害見舞金登録',
  BEN010: '盗難見舞金登録',
  BEN011: '退会餞別金登録',
  BEN012: '給付登録/給付履歴',

  IMP002: 'WAVE給付申請データ取込',
  IMP003: '一括申請データ取込 家族弔慰金',
  IMP004: '一括申請データ取込 配偶者健診補助金',
  IMP005: '一括申請データ取込 退会餞別金',
  IMP006: '連携ファイル取込履歴',

  APA001: '給付明細作成',
  APA002: '承認一覧',
  APA003: '承認登録',
  APA004: '支払データ一覧',
  APA005: '支払データ作成',

  MEN001: '共済タイプマスタ登録',
  MEN002: '会社グループマスタ登録',
  MEN003: '会社マスタ登録',
  MEN004: '加入者マスタ登録',
  MEN005: 'ユーザーマスタ登録',
  MEN006: '提出書類マスタ登録',
  MEN007: '加入者マスタ(EXCEL入出力)',

  MSE001: '共済タイプマスタ一覧',
  MSE002: '会社グループマスタ一覧',
  MSE003: '会社マスタ一覧',
  MSE004: '加入者マスタ一覧',
  MSE005: 'ユーザーマスタ一覧',
  MSE006: '提出書類マスタ一覧',

  SAP001: 'SAP会員データ取込',
  SAP002: 'SAP連携予定データ取込',
  SAP003: 'SAP連携予定データ照合',
  SAP004: 'SAP共済掛金集計データ取込',

  PER001: '共済給付金支払一覧作成',
  PER002: '月別給付集計表作成',
  PER003: '共済掛金請求管理',
  PER004: '共済掛金台帳',
  PER005: '加入引受年齢到達通知書作成',
  PER006: '加入者一覧作成',

  IKU001: '遺児育英年金_給付申請登録',
  IKU003: '遺児育英年金_給付申請一覧',

  HIS001: '給付データ出力（一覧）',
  HIS002: '給付データ出力（詳細）',

  COM001: '共済会システムメニュー',
  COM002: '共済会システムログイン',
  COM003: 'パスワード変更',
};
const dataWijimoKey = {
  [env.STAGING]:
    'kyousaikai-frontend-dev.s3-website.ap-northeast-1.amazonaws.com,735983279763199#B0ijOklkIs4nIxYHMyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TUFZjS7NVZatSe82CTzJ5bqNFRxEGUMVWQrpmT68WULB7MH3WZ6UnMK3mVxU6K4lFZMd7U7E4N8sEb7ZXaUVmVyg7c7AzUJFDSjVFO4l5MNl4dvgFSGdGV544YiFkbrBVVXJ7Zo34dxE5RYNnRsVGS6QXTjtUTthzNYtyTVZWdrlnUv36bTBldMllSWN7NwJFRUVTUjZmNE3GS74GMBVHbHZGcnJHR9M7VvJjVj9WOPVXWotkMwtmWzZnbrcHOSVFS8g7cytCUFh7duJTZP96NDNXQyhTR7lnSwF7NSVVNvMzNtFzR5UHexdnTOF6RmdjWjRXe7xUNrFHewB7YZVHdoBDWrlER69WQNFDR0NjWphTTypWM546VVdUaFVTdXhVYQBTaOBDd6w6Z8IzY756dkNWbyQ6Q9gUSvZjSBJTahV5czkjcMdUS0dTRPR5Vxh6RaVjZ6AlI0IyUiwiIykTR7YTN8QjI0ICSiwCNycjM7YTNwEjM0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiI4UTOxYDMgUTMxADMyAjMiojI4J7QiwiIt36YuM7dh96b0FWbh9SMtQ7chVGa4J7bu5Cch9SZ4l6ciV6dtMzcuYXZk5CZuVGdu3mcm5SahtWahNXdvl7aiojIz5GRiwiIIO88zO88hO888K88NO88eO889K88vK88DO88sO88iK88iojIh94QiwiI9kTMzYzN9cjMzgTO5nzmi',
  [env.PRODUCTION]:
    'rgfukushikai.com,468895415486687#B0BHnhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPnhWOzIjRZ3yUZNlWwcDRqZGNKlWMvgFRLF7caNXNDhHayh7cx4mcjRXSypVamFXMkBVSZNDZUNXOtZ4MEJTTwxGWYplSYBnUvUGSZFUVLFXUHNGN4FlUEJES4F7QQJnWthGVTBjcZBXQVhmQIZ4czAjT7AXNv3UZvBVWyUkR696cjZXeptGVjtmamFERlpGOkV7RxolZONFVzcFekVzb0VHVhtCWk5kW4IjdC34YzUlTnlnNVZFdrlHZo56UzNWU6ADdnJ6R5Rjb7NHU4h5QV3mYCZjYWlGdqJHRK3WbOtEN4InQm3ybPVFOFVUZhpERUh7aVpXY9V4VYJXR4hEZ4U5YE56TlRVUFRlZot4SyY6cxdkbzJHe4tkSh3GO6dXWzhlNxlnWyNFM6NkNrtkNBdUTyRzK8VHSxl4SUZ7K9NHMxtWORdTeTlVSolTNIFzMxlEZ4smSiojITJCLiAjQwYUMCJDNiojIIJCLzQTM5kjM4MDO0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiI6EDNyATMgATMyADMyAjMiojI4J7QiwiIt36YukWYrlGazV7a5Z6ZyJiOiMXbEJCLig8gjP1gjH0gjjrgj78gj19gjnrgj/qgjP8gjz0gjLqgjLiOiEmTDJCLicDO6YDO4UTM4UTO8gjN4IiOiQWSiwSfiEjdwIDMyIiOiIXZ6lCjl',
};
export const WIJIMO_KEY = dataWijimoKey[STAGE];
